<template>
  <div>
    <el-table
      :data="tablelist"
      row-key="id"
      :show-header="false"
      v-loading="loading"
    >
      <el-table-column width="100">
        <template #default="scope">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <el-image
                      style="width: 49px; height: 49px"
                      :src="backImgUrl"
                      fit="contain"
                    />
                    <el-image
                      style="width: 23px; height: 22px; position: absolute"
                      :src="imgUrl"
                      fit="contain"
                    />
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: 5px;
                    "
                  >
                    <span
                      style="
                        width: 50px;
                        text-align: center;
                        color: white;
                        border-radius: 2px;
                      "
                      :style="{
                        'background-color': statusBgColor[scope.row.statusCode],
                      }"
                    >
                      {{ scope.row.statusName }}
                    </span>
                  </div>
                </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-row :span="24">
            <el-col :span="18">
              <span>{{ scope.row.name }}</span>
            </el-col>
            <el-col :span="6" style="text-align: right" v-show="scope.row.statusName =='签合同' ">
              <el-tooltip content="安装状态">
                <img
                  src="@/assets/install.png"
                  style="width: 20px; height: 20px"
                  @click="installStatus(scope.row.id)"
                />
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-descriptions>
                <el-descriptions-item label="创建时间：" span="1" width="200">{{
                  scope.row.createTime
                }}</el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        v-model="show"
        width="400px"
        height="400px"
        title="安装任务状态分布"
      >
        <bar :id="id"/>
      </el-dialog>
  </div>
</template>

<script>
import Bar from "@/view/business_statistics/bar.vue";
export default {
  name: "",
  props: {
    cardlist: {
      type: Object,
      required: true,
    },
  },
  components: {
    Bar,
  },
  emits:['visible'],
  data() {
    return {
      statusBgColor: {
        tracking: "#8080FF",
        signing: "#409eff",
        signed: "#039D12",
        suspend: "#F59A23",
        terminated: "#AAAAAA",
      },
      imgUrl: require(`/src/assets/project.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      tablelist: [],
      show: false,
      id:null
    };
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.tablelist.push(this.cardlist);
    },
    installStatus(id) {
      this.id=id
      this.show = true;
    },
  },
};
</script>

<style scoped></style>
