<template>
  <div>
    <div ref="taskStatusPie" style="height: 200px; width: 100%"></div>
  </div>
</template>

<script>
import {getStatusStatistics} from "@/api/installation_task";
import {isResOK} from "@/api/response";
import {listInstallationPlan} from "@/api/installation_plan";

export default {
  name: "",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statusStatistics: [],
    };
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        title: {
          left: "center",
          padding: [20, 0, 0, 0],
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "20%",
          top: "center",
          orient: "vertical",
        },
        series: [
          {
            name: "状态分布",
            type: "pie",
            radius: ["40%", "50%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      };
    },
  },
  mounted() {
    this.fetchTaskStatusStatistics();
  },
  methods: {
    fetchTaskStatusStatistics() {
      this.statusStatistics = []
      listInstallationPlan({projectId: this.id}).then(res => {
        if (isResOK(res)) {
          if (res.rows.length > 0) {
            const promises = []
            res.rows.forEach(item => {
              promises.push(this.fetchInstallationPlanStatusStatistics(item.id))
            })
            Promise.all(promises).then(installationPlan => {
              console.log(installationPlan)
              let installing = 0
              let check = 0
              let fail = 0
              let completed = 0
              installationPlan.forEach(r => {
                r.forEach(item => {
                  if (item.statusCode === 'installing') {
                    installing += item.count
                  } else if (item.statusCode === 'check') {
                    check += item.count
                  } else if (item.statusCode === 'fail') {
                    fail += item.count
                  } else if (item.statusCode === 'completed') {
                    completed += item.count
                  }
                })
              })
              this.statusStatistics = [
                {
                  count: installing,
                  statusName: "等待安装"
                },
                {
                  count: check,
                  statusName: "等待验收"
                },
                {
                  count: fail,
                  statusName: "验收失败"
                },
                {
                  count: completed,
                  statusName: "已完成"
                },
              ]
              this.drawChart()
            })
          }
        }
      })
    },
    fetchInstallationPlanStatusStatistics(installationPlanId) {
      return new Promise(resolve => {
        getStatusStatistics({installationPlanId: installationPlanId}).then((res) => {
          if (isResOK(res)) {
            resolve(res.data)
          }
        });
      })
    },

    drawChart() {
      this.chartOption.series[0].data = [];
      this.statusStatistics.forEach((item) => {
        this.chartOption.series[0].data.push({
          name: item.statusName + `:  ${item.count}`,
          value: item.count,
        });
      });
      this.chart ||= this.$echarts.init(this.$refs.taskStatusPie);
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true);
      // 添加表格自适应
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
  },
};
</script>

<style scoped></style>
