<template>
  <div>
    <el-main>
      <search-business @submit="submitSearch" />
      <div>
        <el-row v-if="total">
          <el-col v-for="(item, index) in data" :key="item.id" :span="8">
            <el-card :body-style="{ padding: '0px' }" style="margin: 10px 10px"
              ><Table :cardlist="item" @visible="showDialog"></Table
            ></el-card>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col> <el-empty description="暂无数据" /></el-col>
        </el-row>
      </div>
      <pagination
        v-show="total > 0"
        :total="total"
        v-model:page="query.pageNum"
        v-model:limit="query.pageSize"
        @pagination="fetchData"
      />
    </el-main>
  </div>
</template>

<script>
import { listProject } from "@/api/project";
import { isResOK } from "@/api/response";
import Pagination from "@/components/Pagination/index.vue";
import SearchBusiness from "@/view/business_statistics/Search.vue";

import Table from "@/view/business_statistics/table.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";
export default {
  name: "",
  components: {
    SearchBusiness,
    Pagination,
    Table,
  },
  data() {
    return {
      data: [],
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      statusBgColor: {
        tracking: "#8080FF",
        signing: "#409eff",
        signed: "#039D12",
        suspend: "#F59A23",
        terminated: "#AAAAAA",
      },
      imgUrl: require(`/src/assets/project.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      show: false,
    };
  },
  created() {
    this.fetchData();
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    fetchData() {
      this.loading = true;
      listProject(Object.assign({}, this.query))
        .then((res) => {
          if (isResOK(res)) {
            this.data = res.rows;
            this.total = res.total;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({}, { pageNum: this.query.pageNum }, search);
      this.fetchData();
    },
    showDialog(show) {
      this.show = show;

    },
  },
};
</script>
